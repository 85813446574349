<template>
  <c-box :px="['20px', '0']">
    <c-form-control :is-disabled="isDisabled" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Upload Foto Makanan
      </c-form-label>
      <c-flex>
        <c-form-label
          is-invalid
          error-border-color="red.300"
          border="1px solid #C4C4C4"
          :for="`photo-${category}`"
          width="180px"
          height="180px"
          borderRadius="5px"
          overflow="hidden"
          cursor="pointer"
          :backgroundImage="
            photoUrl == null ? 'url(/images/empty-photo.png)' : photoUrl
          "
          :backgroundSize="photoUrl == null ? '63px 63px' : 'cover'"
          backgroundPosition="center center"
          backgroundRepeat="no-repeat"
          marginRight="30px"
        >
          <input
            :id="`photo-${category}`"
            :name="`photo-${category}`"
            v-chakra
            type="file"
            opacity="0"
            display="none"
            @change="onChange"
          />
        </c-form-label>
        <c-flex width="70%" justifyContent="center" alignItems="center">
          <c-text
            color="gray.900"
            fontSize="16px"
            lineHeight="24px"
            fonrFamily="Roboto"
          >
            Anda tidak perlu mengisi form di bawah jika telah mengupload foto
            makanan
          </c-text>
        </c-flex>
      </c-flex>
    </c-form-control>
    <template v-if="photoUrl == null">
      <c-form-control :is-disabled="isDisabled" marginBottom="30px" :is-required="photoUrl == null" :is-invalid="$v.stapleFood.$invalid">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Makanan Pokok atau Sumber Karbohidrat
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            :name="`staplefood-${category}`"
            :id="`staplefood-${category}`"
            placeholder="Makanan Pokok atau Sumber Karbohidrat"
            height="62px"
            v-model="stapleFood"
            :required="photoUrl == null"
          />
        </c-input-group>
        <c-form-helper-text>
          Tulis tidak ada atau ketik strip '-' jika tidak ada konsumsi makanan
          pada bagian ini
        </c-form-helper-text>
        <c-form-helper-text v-if="!$v.stapleFood.required" fontSize="12px" color="red.500">
          Harus diisi
        </c-form-helper-text>
      </c-form-control>
      <c-form-control :is-disabled="isDisabled" marginBottom="30px" :is-required="photoUrl == null" :is-invalid="$v.animalSideDish.$invalid">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Lauk hewani (contoh: ayam, daging, telur, ikan, susu)
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            is-invalid
            error-border-color="red.300"
            placeholder="Lauk hewani (contoh: ayam, daging, telur, ikan, susu)"
            height="62px"
            :name="`animal-side-dish-${category}`"
            :id="`animal-side-dish-${category}`"
            v-model="animalSideDish"
            :required="photoUrl == null"
          />
        </c-input-group>
        <c-form-helper-text>
          Tulis tidak ada atau ketik strip '-' jika tidak ada konsumsi makanan
          pada bagian ini
        </c-form-helper-text>
        <c-form-helper-text v-if="!$v.animalSideDish.required" fontSize="12px" color="red.500">
          Harus diisi
        </c-form-helper-text>
      </c-form-control>
      <c-form-control :is-disabled="isDisabled" marginBottom="30px" :is-required="photoUrl == null" :is-invalid="$v.vegetableSideDish.$invalid">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Lauk nabati (contoh: tahu, tempe, kacang-kacangan)
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            is-invalid
            error-border-color="red.300"
            placeholder="Lauk nabati (contoh: tahu, tempe, kacang-kacangan)"
            height="62px"
            v-model="vegetableSideDish"
            :name="`vegetable-side-dish-${category}`"
            :id="`vegetable-side-dish-${category}`"
            :required="photoUrl == null"
          />
        </c-input-group>
        <c-form-helper-text>
          Tulis tidak ada atau ketik strip '-' jika tidak ada konsumsi makanan
          pada bagian ini
        </c-form-helper-text>
        <c-form-helper-text v-if="!$v.vegetableSideDish.required" fontSize="12px" color="red.500">
          Harus diisi
        </c-form-helper-text>
      </c-form-control>
      <c-form-control :is-disabled="isDisabled" marginBottom="30px" :is-required="photoUrl == null" :is-invalid="$v.vegetable.$invalid">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Sayur
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            is-invalid
            error-border-color="red.300"
            placeholder="Sayur"
            height="62px"
            v-model="vegetable"
            :name="`vegetable-${category}`"
            :id="`vegetable-${category}`"
            :required="photoUrl == null"
          />
        </c-input-group>
        <c-form-helper-text>
          Tulis tidak ada atau ketik strip '-' jika tidak ada konsumsi makanan
          pada bagian ini
        </c-form-helper-text>
        <c-form-helper-text v-if="!$v.vegetable.required" fontSize="12px" color="red.500">
          Harus diisi
        </c-form-helper-text>
      </c-form-control>
      <c-form-control :is-disabled="isDisabled" marginBottom="30px" :is-required="photoUrl == null" :is-invalid="$v.fruit.$invalid">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Buah
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            is-invalid
            error-border-color="red.300"
            placeholder="Buah"
            height="62px"
            v-model="fruit"
            :name="`fruit-${category}`"
            :id="`fruit-${category}`"
            :required="photoUrl == null"
          />
        </c-input-group>
        <c-form-helper-text>
          Tulis tidak ada atau ketik strip '-' jika tidak ada konsumsi makanan
          pada bagian ini
        </c-form-helper-text>
        <c-form-helper-text v-if="!$v.fruit.required" fontSize="12px" color="red.500">
          Harus diisi
        </c-form-helper-text>
      </c-form-control>
      <c-form-control :is-disabled="isDisabled" marginBottom="30px" :is-required="photoUrl == null" :is-invalid="$v.drink.$invalid">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Minuman
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            is-invalid
            error-border-color="red.300"
            placeholder="Minuman"
            height="62px"
            v-model="drink"
            :name="`drink-${category}`"
            :id="`drink-${category}`"
            :required="photoUrl == null"
          />
        </c-input-group>
        <c-form-helper-text>
          Tulis tidak ada atau ketik strip '-' jika tidak ada konsumsi makanan
          pada bagian ini
        </c-form-helper-text>
      </c-form-control>
    </template>
  </c-box>
</template>

<script>
import {requiredIf} from "vuelidate/lib/validators";

export default {
  name: "form-makanan",
  props: ["category", "value", "is-disabled"],
  data() {
    return {
      stapleFood: null,
      animalSideDish: null,
      vegetableSideDish: null,
      vegetable: null,
      fruit: null,
      drink: null,
      photoUrl: null,
      // ---
      photoFile: null,
    };
  },
  validations: {
    stapleFood: {required: requiredIf(v => v.photoUrl == null)},
    animalSideDish: {required: requiredIf(v => v.photoUrl == null)},
    vegetableSideDish: {required: requiredIf(v => v.photoUrl == null)},
    vegetable: {required: requiredIf(v => v.photoUrl == null)},
    fruit: {required: requiredIf(v => v.photoUrl == null)},
    drink: {required: requiredIf(v => v.photoUrl == null)},
  },
  computed: {
    isPhotoAttached() {
      return this.photoUrl != null;
    },
    submitValue() {
      return {
        category: this.category,
        stapleFood: this.stapleFood,
        animalSideDish: this.animalSideDish,
        vegetableSideDish: this.vegetableSideDish,
        vegetable: this.vegetable,
        fruit: this.fruit,
        drink: this.drink,
        photoUrl: this.photoUrl,
        photoFile: this.photoFile,
      };
    },
  },
  watch: {
    photoFile(val) {
      if (this.photoUrl != null && this.photoUrl.startsWith("blob")) {
        URL.revokeObjectURL(this.photoUrl);
      }
      this.photoUrl = URL.createObjectURL(val);
    },
    value: {
      immediate: true,
      handler(val) {
        if (val == null) return;

        this.stapleFood = val.stapleFood;
        this.animalSideDish = val.animalSideDish;
        this.vegetableSideDish = val.vegetableSideDish;
        this.vegetable = val.vegetable;
        this.fruit = val.fruit;
        this.drink = val.drink;
        this.photoUrl = val.photoUrl;
      },
    },
    submitValue: {
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    onChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.photoFile = files[0];
    },
  },
};
</script>
