<template>
  <c-box id="kuisioner">
    <Header />
    <c-box paddingTop="30px" paddingBottom="80px" :px="['20px', '0']">
      <c-box w="100%" maxWidth="1200px" mx="auto">
        <Breadcrumb
            :items="[
            {
              label: 'Beranda',
              href: $router.resolve({ name: 'client.index' }).href,
            },
            {
              label: 'Kuisioner',
              href: $router.resolve({ name: 'client.kuisioner' }).href,
              isCurrent: true,
            },
          ]"
        />
        <hr v-chakra mt="30px" />
        <Steps :current="5" :total="7" v-chakra mt="30px" />


        <c-box w="100%" maxWidth="590px" mx="auto" marginTop="50px">
          <c-box textAlign="center">
            <c-text
              color="brand.900"
              fontFamily="roboto"
              textTransform="uppercase"
              fontWeight="700"
              fontSize="16px"
              marginBottom="10px"
            >
              Kuisioner (5/7)
            </c-text>
            <c-heading
              fontWeight="600"
              fontSize="36px"
              lineHeight="54px"
              color="black.900"
            >
              Food Record 24 Jam
            </c-heading>
          </c-box>

          <form @submit.prevent="submit">
            <c-box
              marginTop="80px"
              v-chakra="{
                'input[type=radio]:checked+.css-1nskzgf': {
                  bg: 'brand.900',
                  borderColor: 'brand.900',
                },
                'input[type=checkbox]:checked+.css-fd41dq': {
                  bg: 'brand.900',
                  borderColor: 'brand.900',
                },
                'div.css-huvqms': {
                  fontSize: '18px',
                  color: '#555555',
                },
              }"
            >
              <!-- Makan Pagi -->
              <c-form-control :is-disabled="!isEditable" marginBottom="30px">
                <c-text
                  fontSize="15px"
                  color="brand.900"
                  fontWeight="500"
                  lineHeight="27px"
                  fontFamily="Roboto"
                >
                  Silahkan tuliskan makanan di pagi hari atau sarapan yang kamu
                  konsumsi mulai dari jam 00.00 - 09.00 pagi pada hari kemarin
                  (Termasuk minuman selain air mineral)!
                </c-text>
                <FormMakanan
                  ref="breakfastForm"
                  category="breakfast"
                  v-model="breakfast"
                  :is-disabled="!isEditable"
                />
              </c-form-control>
              <!-- EOF Makan Pagi -->

              <!-- Snack Pagi -->
              <c-form-control :is-disabled="!isEditable" marginBottom="30px">
                <c-text
                  fontSize="15px"
                  color="brand.900"
                  fontWeight="500"
                  lineHeight="27px"
                  fontFamily="Roboto"
                >
                  Silahkan tuliskan snack/cemilan pagi yang kamu konsumsi mulai
                  dari jam 10.00 - 11.00 siang pada hari kemarin (Termasuk
                  minuman selain air mineral)!
                </c-text>
                <FormSnack
                  ref="morningSnackForm"
                  category="morning_snack"
                  v-model="morningSnack"
                  :is-disabled="!isEditable"
                />
              </c-form-control>
              <!-- EOF Snack Pagi -->

              <!-- Makan Siang -->
              <c-form-control :is-disabled="!isEditable" marginBottom="30px">
                <c-text
                  fontSize="15px"
                  color="brand.900"
                  fontWeight="500"
                  lineHeight="27px"
                  fontFamily="Roboto"
                >
                  Silahkan tuliskan makan siang yang kamu konsumsi mulai dari
                  jam 12.00 - 14.00 siang pada hari kemarin (Termasuk minuman
                  selain air mineral)!
                </c-text>
                <FormMakanan
                  ref="lunchForm"
                  category="lunch"
                  v-model="lunch"
                  :is-disabled="!isEditable"
                />
              </c-form-control>
              <!-- EOF Makan Siang -->

              <!-- Snack Siang -->
              <c-form-control :is-disabled="!isEditable" marginBottom="30px">
                <c-text
                  fontSize="15px"
                  color="brand.900"
                  fontWeight="500"
                  lineHeight="27px"
                  fontFamily="Roboto"
                >
                  Silahkan tuliskan snack/cemilan sore yang kamu konsumsi mulai
                  dari jam 15.00 - 17.00 sore pada hari kemarin (Termasuk
                  minuman selain air mineral)!
                </c-text>
                <FormSnack
                  ref="afternoonSnackForm"
                  category="afternoon_snack"
                  v-model="afternoonSnack"
                  :is-disabled="!isEditable"
                />
              </c-form-control>
              <!-- EOF Snack Siang -->

              <!-- Makan Malam -->
              <c-form-control :is-disabled="!isEditable" marginBottom="30px">
                <c-text
                  fontSize="15px"
                  color="brand.900"
                  fontWeight="500"
                  lineHeight="27px"
                  fontFamily="Roboto"
                >
                  Silahkan tuliskan atau upload foto makan malam yang kamu
                  konsumsi yang kamu konsumsi mulai dari jam 18.00 - 20.00 malam
                  pada hari kemarin (Termasuk minuman selain air mineral)!
                </c-text>
                <FormMakanan
                  ref="dinnerForm"
                  category="dinner"
                  v-model="dinner"
                  :is-disabled="!isEditable"
                />
              </c-form-control>
              <!-- EOF Makan Malam -->

              <!-- Snack Malam -->
              <c-form-control :is-disabled="!isEditable" marginBottom="30px">
                <c-text
                  fontSize="15px"
                  color="brand.900"
                  fontWeight="500"
                  lineHeight="27px"
                  fontFamily="Roboto"
                >
                  Silahkan tuliskan snack/cemilan malam yang kamu konsumsi mulai
                  dari jam 20.00 - 22.00 sore pada hari kemarin (Termasuk
                  minuman selain air mineral)!
                </c-text>
                <FormSnack
                  ref="nightSnackForm"
                  category="night_snack"
                  v-model="nightSnack"
                  :is-disabled="!isEditable"
                />
              </c-form-control>
              <!-- EOF Snack Malam -->

              <DinamisForm v-model="questions" />

              <c-flex
                justifyContent="space-between"
                alignItems="center"
                marginTop="30px"
              >
                <c-button
                  left-icon="chevron-left"
                  backgroundColor="brand.900"
                  color="white"
                  borderRadius="100px"
                  variant="solid"
                  px="30px"
                  as="router-link"
                  :to="{ name: 'client.kuisioner4', params: $route.params }"
                >
                  Kembali
                </c-button>
                <c-button
                  right-icon="chevron-right"
                  backgroundColor="brand.900"
                  color="white"
                  borderRadius="100px"
                  variant="solid"
                  px="30px"
                  type="submit"
                  :is-disabled="isLoading"
                  id="submit"
                >
                  {{ isLoading ? "Submitting..." : "Selanjutnya" }}
                </c-button>
              </c-flex>
            </c-box>
          </form>
        </c-box>
      </c-box>
    </c-box>
    <Footer />
  </c-box>
</template>

<script>
import Header from "@/components/header/index.vue";
import Footer from "@/components/Footer.vue";
import FormMakanan from "@/views/client/kuisioner/forms/form-makanan";
import FormSnack from "@/views/client/kuisioner/forms/form-snack";
import { mapGetters, mapState } from "vuex";
import Breadcrumb from "@/components/breadcrumb"
import Steps from "@/components/steps"
import DinamisForm from "@/views/client/kuisioner/dinamis-form"


export default {
  name: "kuisioner5",
  components: {
    FormMakanan,
    Header,
    Footer,
    FormSnack,
    Breadcrumb,
    Steps,
    DinamisForm,
  },
  data() {
    return {
      breakfast: null,
      morningSnack: null,
      lunch: null,
      afternoonSnack: null,
      dinner: null,
      nightSnack: null,
      // ---
      isLoading: false,
      //-- Dinamis form
      questions: [],
    };
  },
  computed: {
    ...mapState({
      kuisData: (s) => s.kuisioner.foodRecords,
    }),
    ...mapGetters({
      axios: "axios",
    }),
    isEditable() {
      return this.$route.params.isEditable ?? true;
    },
    answers() {
      return this.questions.map((it) => ({
        questionId: it.id,
        answer: it.answer,
        question: it.question,
      }))
    },
  },
  watch: {
    kuisData: {
      immediate: true,
      handler(val) {
        if (val == null) return;
        if (
          !Array.isArray(val) &&
          Object.getPrototypeOf(val) === Object.prototype
        )
          val = Object.values(val);

        this.breakfast = val.find((it) => it.category === "breakfast");
        this.lunch = val.find((it) => it.category === "lunch");
        this.dinner = val.find((it) => it.category === "dinner");
        this.afternoonSnack = val.find(
          (it) => it.category === "afternoon_snack"
        );
        this.morningSnack = val.find((it) => it.category === "morning_snack");
        this.nightSnack = val.find((it) => it.category === "night_snack");
      },
    },
    programId: {
      immediate: true,
      async handler(programId) {
        if (programId == null) return;

        await this.$store.dispatch("kuisioner/getV2ClientForCategory", "food_records")
        await this.$store.dispatch("kuisioner/getV2ClientDraft", programId)
        this.questions = this.$store.getters["kuisioner/questionFor"]("food_records")
      }
    },
  },
  methods: {
    async submit() {
      let $vees = [
        this.$refs.breakfastForm.$v,
        this.$refs.morningSnackForm.$v,
        this.$refs.lunchForm.$v,
        this.$refs.afternoonSnackForm.$v,
        this.$refs.dinnerForm.$v,
        this.$refs.nightSnackForm.$v,
      ];
      $vees.forEach(($v) => $v.$touch());

      if ($vees.some(($v) => $v.$invalid)) {
        return;
      }

      this.isLoading = true;
      let value = [
        this.breakfast,
        this.morningSnack,
        this.lunch,
        this.afternoonSnack,
        this.dinner,
        this.nightSnack,
      ];

      try {
        for (let data of value) {
          if (data.photoFile != null && data.photoFile instanceof File) {
            let formData = new FormData();
            formData.append("file", data.photoFile);
            let url = await this.axios
              .post(`/v1/clients/quizioner/upload`, formData)
              .then((r) => r.data.data?.url);

            data.photoUrl = url;
          }
        }

        await this.$store.dispatch("kuisioner/setFoodRecords", {
          programId: this.$route.params.programId,
          isDraft: true,
          value,
          answers: this.answers,
        });
        await this.$router.push({
          name: "client.kuisioner6",
          params: this.$route.params,
        });
      } catch (e) {
        this.$errorToast({
          message: e.response?.data?.message ?? e.toString(),
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
