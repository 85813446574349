<template>
  <c-box>
    <c-form-control :is-disabled="isDisabled" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Upload Foto Makanan
      </c-form-label>
      <c-flex>
        <c-form-label
          is-invalid
          error-border-color="red.300"
          border="1px solid #C4C4C4"
          :for="`photo-${category}`"
          width="180px"
          height="180px"
          borderRadius="5px"
          overflow="hidden"
          :cursor="isDisabled ? [''] : ['pointer']"
          :backgroundImage="
            photoUrl == null ? 'url(/images/empty-photo.png)' : photoUrl
          "
          :backgroundSize="photoUrl == null ? '63px 63px' : 'cover'"
          backgroundPosition="center center"
          backgroundRepeat="no-repeat"
          marginRight="30px"
        >
          <input
            v-chakra
            type="file"
            name="photoSnack"
            :id="`photo-${category}`"
            opacity="0"
            display="none"
            :disabled="isDisabled"
            @change="onChange"
          />
        </c-form-label>
        <c-flex width="70%" justifyContent="center" alignItems="center">
          <c-text
            color="gray.900"
            fontSize="16px"
            lineHeight="24px"
            fonrFamily="Roboto"
          >
            Anda tidak perlu mengisi form di bawah jika telah mengupload foto
            makanan
          </c-text>
        </c-flex>
      </c-flex>
    </c-form-control>
    <c-form-control :is-disabled="isDisabled"
      v-if="photoUrl == null"
      marginBottom="80px"
      :is-required="photoUrl == null"
      :is-invalid="$v.snack.$invalid"
    >
      <c-form-label
        fontSize="16px"
        color="#555555"
        fontWeight="400"
        fontFamily="Roboto"
      >
        Tuliskan snack anda di sini
      </c-form-label>
      <!-- <c-input-group size="md"> -->
      <textarea
        rows="3"
        v-chakra
        placeholder="Tuliskan di sini"
        d="block"
        border="1px solid #E2E8F0"
        border-radius="md"
        minHeight="150px"
        type="text"
        lineHeight="20px"
        p="10px"
        w="100%"
        v-model="snack"
        :required="photoUrl == null"
        :disabled="isDisabled"
        :name="`snack-${category}`"
        :id="`snack-${category}`"
      />
      <!-- </c-input-group> -->
      <c-form-helper-text>
        Tulis tidak ada atau ketik strip '-' jika tidak ada konsumsi makanan
        pada bagian ini
      </c-form-helper-text>

      <!-- Show error with c-form-helper-text component -->
      <c-form-helper-text
        v-if="!$v.snack.required"
        color="red.300"
        fontSize="12px"
      >
        Harus diisi
      </c-form-helper-text>
    </c-form-control>
  </c-box>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";

export default {
  name: "form-snack",
  props: ["category", "value", "is-disabled"],
  data() {
    return {
      snack: null,
      photoUrl: null,
      photoFile: null,
    };
  },
  validations: {
    photoUrl: {},
    snack: { required: requiredIf((v) => v.photoUrl == null) },
  },
  computed: {
    submittedValue() {
      return {
        category: this.category,
        snack: this.snack,
        photoFile: this.photoFile,
        photoUrl: this.photoUrl,
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val == null) return;

        this.snack = val.snack;
        this.photoUrl = val.photoUrl;
      },
    },
    submittedValue(val) {
      this.$emit("input", val);
    },
    photoFile(val) {
      if (val == null) return;

      if (this.photoUrl != null && this.photoUrl.startsWith("blob")) {
        URL.revokeObjectURL(this.photoUrl);
      }

      this.photoUrl = URL.createObjectURL(val);
    },
  },
  methods: {
    onChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.photoFile = files[0];
    },
  },
};
</script>
