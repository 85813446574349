import { render, staticRenderFns } from "./form-snack.vue?vue&type=template&id=50f41f92&"
import script from "./form-snack.vue?vue&type=script&lang=js&"
export * from "./form-snack.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFormLabel: require('@chakra-ui/vue').CFormLabel, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CFormControl: require('@chakra-ui/vue').CFormControl, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CBox: require('@chakra-ui/vue').CBox})
